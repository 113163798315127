/* ==========================================================================
   DL Menu
   ========================================================================== */

.dl-menuwrapper {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1000;
	-webkit-perspective: 1000px;
	-moz-perspective: 1000px;
	perspective: 1000px;
	-webkit-perspective-origin: 50% 200%;
	-moz-perspective-origin: 50% 200%;
	perspective-origin: 50% 200%;

	@media #{$medium} {
		position: fixed;
		max-width: 175px;
		top: 25px;
		left: 25px;
	}

	&.dl-menuopen {
		width: 100%;
		height: 90%;
	}

	button {
		top: 0;
		left: 0;
		background: $comp-color;
		border: none;
		width: 48px;
		height: 45px;
		text-indent: -900em;
		overflow: hidden;
		position: relative;
		cursor: pointer;
		outline: none;
		border-radius: 0 0 3px 0;
		opacity: 0.6;
		box-shadow: 0 12px 24px rgba(#000,0.4);
		transition: 0.4s ease-in-out;

		&:hover {
			opacity: 1;
		}

		@media #{$medium} {
			border-radius: 3px;
		}
	}

	button:hover,
	button.dl-active,
	ul {
		background: #aaa;
	}

	button:after {
		content: '';
		position: absolute;
		width: 68%;
		height: 5px;
		background: #fff;
		top: 10px;
		left: 16%;
		box-shadow:
			0 10px 0 #fff,
			0 20px 0 #fff;
	}

	ul {
		padding: 0;
		list-style: none;
		-webkit-transform-style: preserve-3d;
		-moz-transform-style: preserve-3d;
		transform-style: preserve-3d;
	}

	li {
		position: relative;

		h4 {
			margin:0;
			padding: 15px 20px 0;
			color: rgba(#fff,0.9);
		}

		p {
			margin: 0;
			padding: 15px 20px;
			@include font-rem(14);
			color: rgba(#fff,0.8);
			font-weight: 300;

			a {
				display: inline;
				padding: 0;
				@include font-rem(14);
			}
		}

		a {
			display: block;
			position: relative;
			padding: 15px 20px;
			@include font-rem(14);
			line-height: 20px;
			font-weight: 400;
			color: #fff;
			outline: none;
		}

		&.dl-back > a {
			padding-left: 30px;
			background: rgba(0,0,0,0.2);
		}

		&.dl-back:after,
		> a:not(:only-child):after {
			position: absolute;
			top: 0;
			line-height: 50px;
			font-family: "fontawesome";
			color: #fff;
			speak: none;
			-webkit-font-smoothing: antialiased;
			content: "\f105";
		}

		&.dl-back:after {
			left: 10px;
			color: rgba(212,204,198,0.5);
			-webkit-transform: rotate(180deg);
			-moz-transform: rotate(180deg);
			transform: rotate(180deg);
		}

		> a:after {
			right: 10px;
			color: rgba(0,0,0,0.15);
		}
	}

	.dl-menu {
		margin: 5px 0 0 0;
		position: relative;
		width: 100%;
		max-height: 90%;
		overflow-y: auto;
	  overflow-x: hidden;
		opacity: 0;
		pointer-events: none;
		box-shadow: 0 12px 24px rgba(#000,0.4);
		-webkit-transform: translateY(10px);
		-moz-transform: translateY(10px);
		transform: translateY(10px);
		-webkit-backface-visibility: hidden;
		-moz-backface-visibility: hidden;
		backface-visibility: hidden;
		z-index: inherit;

		@media #{$medium} {
			border-radius: 3px;
		}
	}

	.dl-menu.dl-menu-toggle {
		-webkit-transition: all 0.3s ease;
		-moz-transition: all 0.3s ease;
		transition: all 0.3s ease;
	}

	.dl-menu.dl-menuopen {
		opacity: 1;
		pointer-events: auto;
		-webkit-transform: translateY(0px);
		-moz-transform: translateY(0px);
		transform: translateY(0px);
	}

	.dl-submenu {
		border-radius: 3px;
		box-shadow: 0 12px 24px rgba(#000,0.4);

		.btn {
			margin-bottom: 0;
		}
	}

	/* Hide the inner submenus */
	li .dl-submenu {
		display: none;
	}
}

/*
When a submenu is opened, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
	display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
	display: block;
}

/* Animation classes for moving out and in */
.dl-menu.dl-animate-out {
	-webkit-animation: MenuAnimOut 0.4s ease;
	-moz-animation: MenuAnimOut 0.4s ease;
	animation: MenuAnimOut 0.4s ease;
}

.dl-menu.dl-animate-in {
	-webkit-animation: MenuAnimIn 0.4s ease;
	-moz-animation: MenuAnimIn 0.4s ease;
	animation: MenuAnimIn 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-in {
	-webkit-animation: SubMenuAnimIn 0.4s ease;
	-moz-animation: SubMenuAnimIn 0.4s ease;
	animation: SubMenuAnimIn 0.4s ease;
}

.dl-menuwrapper > .dl-submenu.dl-animate-out {
	-webkit-animation: SubMenuAnimOut 0.4s ease;
	-moz-animation: SubMenuAnimOut 0.4s ease;
	animation: SubMenuAnimOut 0.4s ease;
}

/* No Touch Fallback */
.no-touch .dl-menuwrapper li a:hover {
	background: rgba(255,248,213,0.1);
}

/* No JS Fallback */
.no-js {
	.dl-trigger {
		display: none;
	}
	.dl-menuwrapper {
		position: initial;

		@media #{$medium} {
			position: absolute;
		}
	}

	.dl-menuwrapper .dl-menu {
		position: relative;
		opacity: 1;
		pointer-events: auto;
		-webkit-transform: none;
		-moz-transform: none;
		transform: none;
	}

	.dl-menuwrapper li .dl-submenu {
		display: block;
	}

	.dl-menuwrapper li.dl-back {
		display: none;
	}

	.dl-menuwrapper li > a:not(:only-child) {
		background: rgba(0,0,0,0.1);
	}

	.dl-menuwrapper li > a:not(:only-child):after {
		content: '';
	}

	.dl-menu {
		max-height: 100%;
	}

	.dl-menu li {
		display: block;
	}
}

// Menu Color
.dl-menuwrapper button:hover,
.dl-menuwrapper button.dl-active,
.dl-menuwrapper ul {
	background: $comp-color;
}

// Fix for IE
.dl-menu li {
	display: none
}

.dl-menuopen li {
	display: block
}
